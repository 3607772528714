jQuery(document).ready(($) => {
  /* IE popup
  ========================================================================== */

  checkIfIE();

  function checkIfIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      alert(
        'For an optimal viewing experience, please choose a different browser.'
      );
    }

    return false;
  }

  /* Set VW without horizontal scrollbar
  ========================================================================== */

  setVw();
  function setVw() {
    const vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', ''.concat(vw, 'px'));
  }

  window.addEventListener('resize', (params) => {
    setVw();
  });

  /* Accordion
  ========================================================================== */

  $('.accordion').on('click', function () {
    $(this)
      .toggleClass('active')
      .next('.panel')
      .toggleClass('active')
      .slideToggle();
  });

  /* Dynamically adjust the posts width in Post archive - Presenters, Topics, Courses, Lectures
  ========================================================================== */

  if ($('.post-list-archive__list').length) {
    const maxWidth = Math.max(
      ...$.map(
        $('.post-list-archive__list .post-list-archive__list-post-link'),
        (elementOrValue) => $(elementOrValue).width()
      )
    );

    $('.post-list-archive__list').attr(
      'style',
      `grid-template-columns: repeat(auto-fill, minmax(min(100%, ${
        maxWidth + 58
      }px), 1fr))`
    );

    window.addEventListener('resize', () => {
      $('.post-list-archive__list').attr(
        'style',
        `grid-template-columns: repeat(auto-fill, minmax(min(100%, ${
          maxWidth + 58
        }px), 1fr))`
      );
    });
  }

  /* Homepage slider
  ========================================================================== */

  /*  $(".homepage-header__slider").slick({
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  }); */

  /* AOS
  ========================================================================== */

  /* const myPromise = new Promise((resolve, reject) => {
    document.querySelectorAll(".aos").forEach((el) => {
      el.className.split(/\s+/).map((item) => {
        if (item.includes("aos_")) {
          let parts = item.split("_");

          if (parts.length > 2) {
            el.setAttribute(`data-aos-${parts[1]}`, parts[2]);
          } else {
            el.setAttribute("data-aos", parts[1]);
          }
        }
      });
    });

    setTimeout(() => {
      resolve();
    }, 100);
  })
    .then(() => {
      AOS.init({
        // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
        initClassName: "aos-init", // class applied after initialization
        animatedClassName: "aos-animate", // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 300, // offset (in px) from the original trigger point
        delay: 100, // values from 0 to 3000, with step 50ms
        duration: 400, // values from 0 to 3000, with step 50ms
        easing: "ease", // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
      });
    })
    .catch((error) => {
      console.log(error);
    }); */
});
